import React, { Fragment } from 'react'

// Gatsby
import { graphql } from 'gatsby'

// Layouts
import MainLayout from '@layouts/MainLayout'

// Components
import SEO from '@components/SEO'
import Tag from '@components/Tag'

const Tags = ({ data }) => {
  const {
    allMdx: { group }
  } = data

  return (
    <Fragment>
      <SEO
        title='Etiketler'
        description='Frontend development ile ilgili etiketler'
        keywords='Frontend, Yazılım, Development, Html, Css, Sass, Javascript, Jquery, React, Gatsby, Git, Etiketler'
      />
      <MainLayout>
        <h1 className='title'>Etiketler</h1>

        <div>
          {group.map((groupItem, index) => {
            const { fieldValue, totalCount } = groupItem

            return <Tag tag={fieldValue} key={index} count={totalCount} hasIcon />
          })}
        </div>
      </MainLayout>
    </Fragment>
  )
}

export default Tags

export const tagsQuery = graphql`
  query tagsQuery {
    allMdx {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
