import React from 'react'

// Gatsby
import { Link } from 'gatsby'

const Tag = ({ tag, hasIcon = false, count = null }) => {
  const tagURL = `/tag/${tag}/`

  if (hasIcon) {
    return (
      <div className='bordered-item'>
        <div className='bordered-item__icon'>
          <i className={`i-${tag}`} />
        </div>
        <div className='bordered-item__content'>
          <Link className='bordered-item__link' to={tagURL}>
            #{tag} {count && <b>({count})</b>}
          </Link>
        </div>
      </div>
    )
  } else {
    return (
      <Link to={tagURL}>
        #{tag} {count && <b>({count})</b>}
      </Link>
    )
  }
}

export default Tag
